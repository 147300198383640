<template>
  <div class="conferences-page">

    <div class="head-links">
      <router-link class="back-links" to="/pediatrics">Головна</router-link>
      <span>/</span>
      <router-link class="now-page" to="/conferences">Конференції</router-link>
    </div>

    <section class="conferences">

      <div class="conferences-filter">
        <div class="conferences-filter-title">Конференції</div>
        <div class="conferences-filter-button">
          <div class="conferences-filter-button-all" @click="isinteresting = false"
               :class="{active_button: !isinteresting}">
            <div>
              <div class="icon">
                <div class="count">{{ postObject.length }}</div>
                <svg
                    width="37"
                    height="41"
                    viewBox="0 0 37 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M36.4825 39.6003L32.5031 35.4376C33.6671 34.1583 34.3831 32.4656 34.3831 30.6043C34.3831 26.6356 31.1545 23.407 27.1858 23.407C23.2171 23.407 19.9885 26.6356 19.9885 30.6043C19.9885 34.573 23.2165 37.801 27.1851 37.801C28.8125 37.801 30.3105 37.2516 31.5178 36.337L35.5185 40.5216C35.6498 40.6583 35.8245 40.7276 36.0005 40.7276C36.1665 40.7276 36.3318 40.6663 36.4611 40.543C36.7271 40.2883 36.7371 39.8663 36.4825 39.6003ZM27.1851 36.4676C23.9518 36.4676 21.3211 33.837 21.3211 30.6036C21.3211 27.3703 23.9518 24.7396 27.1851 24.7396C30.4185 24.7396 33.0491 27.3703 33.0491 30.6036C33.0491 33.837 30.4185 36.4676 27.1851 36.4676Z"
                      fill="#1E3B89"
                  ></path>
                  <path
                      d="M18.0002 14.7277H16.6669H12.0002H10.6669H4.66687V20.7277V22.061V26.7277V28.061V34.061H10.6669H12.0002H18.0002V28.061V26.7277V22.061H22.6669H24.0002H30.0002V14.7277H24.0002H22.6669H18.0002ZM10.6669 32.7276H6.0002V28.061H10.6669V32.7276ZM10.6669 26.7277H6.0002V22.061H10.6669V26.7277ZM10.6669 20.7277H6.0002V16.061H10.6669V20.7277ZM16.6669 32.7276H12.0002V28.061H16.6669V32.7276ZM16.6669 26.7277H12.0002V22.061H16.6669V26.7277ZM16.6669 20.7277H12.0002V16.061H16.6669V20.7277ZM22.6669 20.7277H18.0002V16.061H22.6669V20.7277ZM24.0002 16.061H28.6669V20.7277H24.0002V16.061Z"
                      fill="#1E3B89"
                  ></path>
                  <path
                      d="M19.3333 37.3942H1.33333V11.3942H33.3333V20.7275C33.3333 21.0962 33.6313 21.3942 34 21.3942C34.3686 21.3942 34.6666 21.0962 34.6666 20.7275V10.7275V4.06087C34.6666 3.6922 34.3686 3.3942 34 3.3942H30.6666V1.39421C30.6666 1.02554 30.3686 0.727539 30 0.727539H25.3333C24.9647 0.727539 24.6667 1.02554 24.6667 1.39421V3.3942H9.99999V1.39421C9.99999 1.02554 9.70199 0.727539 9.33333 0.727539H4.66666C4.298 0.727539 4 1.02554 4 1.39421V3.3942H0.666666C0.298 3.3942 0 3.6922 0 4.06087V10.7275V38.0608C0 38.4295 0.298 38.7275 0.666666 38.7275H19.3333C19.702 38.7275 20 38.4295 20 38.0608C20 37.6922 19.702 37.3942 19.3333 37.3942ZM26 4.06087V2.06087H29.3333V4.06087V6.06087H26V4.06087ZM5.33333 4.06087V2.06087H8.66666V4.06087V6.06087H5.33333V4.06087ZM1.33333 4.72754H4V6.72753C4 7.0962 4.298 7.3942 4.66666 7.3942H9.33333C9.70199 7.3942 9.99999 7.0962 9.99999 6.72753V4.72754H24.6667V6.72753C24.6667 7.0962 24.9647 7.3942 25.3333 7.3942H30C30.3686 7.3942 30.6666 7.0962 30.6666 6.72753V4.72754H33.3333V10.0609H1.33333V4.72754Z"
                      fill="#1E3B89"
                  ></path>
                </svg>
              </div>
              <div class="text">Всі</div>
            </div>
          </div>
          <div class="conferences-filter-button-interesting" @click="isinteresting = true"
               :class="{active_button: isinteresting}">
            <div>
              <div class="icon">
                <div class="count">{{ favoritesArray.length }}</div>
                <svg
                    width="39"
                    height="41"
                    viewBox="0 0 39 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M14.3205 17.6896C19.2052 17.6896 23.1652 13.7299 23.1652 8.8448C23.1652 3.96 19.2052 0 14.3205 0C9.43553 0 5.47559 3.96 5.47559 8.8448C5.48003 13.728 9.43762 17.6852 14.3205 17.6896ZM14.3205 1.67352C18.281 1.67352 21.4917 4.88454 21.4917 8.8448C21.4917 12.8056 18.281 16.0161 14.3205 16.0161C10.36 16.0161 7.14909 12.8056 7.14909 8.8448C7.15379 4.88611 10.3619 1.67823 14.3205 1.67352V1.67352Z"
                      fill="#1E3B89"
                  ></path>
                  <path
                      d="M22.612 22.212C22.612 22.212 17.5616 19.8879 14.7751 19.9009H14.7496C10.8905 19.9095 7.30919 21.3892 4.65466 24.0824C1.9664 26.8006 0.491605 30.5162 0.500036 34.5481C0.50188 34.7684 0.589346 34.98 0.744518 35.1383C0.903116 35.295 1.11862 35.3815 1.34282 35.3792L21.5503 35.3376L22.612 22.212ZM5.85942 25.2376C8.19386 22.8685 11.354 21.5636 14.7496 21.5636H14.7751C17.2568 21.5506 19.6853 22.2747 21.7439 23.6417C21.7439 27.3202 21.4997 30.6115 21.4997 33.6751L2.19376 33.7167C2.38766 30.4416 3.6683 27.4576 5.85942 25.2376Z"
                      fill="#1E3B89"
                  ></path>
                  <path
                      d="M37.9406 21.7455H22.0586V40H37.9406V21.7455Z"
                      stroke="#1E3B89"
                      stroke-width="1.2"
                      stroke-miterlimit="10"
                  ></path>
                  <path
                      d="M25.8591 19.9009V23.8844"
                      stroke="#1E3B89"
                      stroke-width="1.2"
                      stroke-miterlimit="10"
                  ></path>
                  <path
                      d="M29.9921 19.9009V23.8844"
                      stroke="#1E3B89"
                      stroke-width="1.2"
                      stroke-miterlimit="10"
                  ></path>
                  <path
                      d="M34.1252 19.9009V23.8844"
                      stroke="#1E3B89"
                      stroke-width="1.2"
                      stroke-miterlimit="10"
                  ></path>
                  <path d="M25.5873 30.704L28.6943 33.7126L33.7077 28.5349" stroke="#1E3B89"></path>
                </svg>
              </div>
              <div class="text">Цікаві вам</div>
            </div>
          </div>
        </div>
        <div class="conferences-filter-select" v-if="!isinteresting">
          <div class="drop-item1" v-bind:class="[dropList1 !== false ? activeClass: '', ``]">
            <p @click="openDropListNeed()" class="visible-item">
              <span>{{ filter1Chose }}</span>

              <svg class="arrow" width="12" height="7" viewBox="0 0 12 7" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M10.7783 1.24219L6.26562 5.77767" stroke="#00ACE6" stroke-width="2"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.26608 5.77767L1.75684 1.24219" stroke="#00ACE6" stroke-width="2"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </p>

            <div class="hide-item">
              <p v-bind:class="[filter1Chose === key.name ? activeClass: '', ``]"
                 v-for="(key, value) in filterItemDrop1" v-html="key.name"
                 @click="choseFilter1(key.type, key.name)"></p>
            </div>

          </div>

          <div class="drop-item2" v-bind:class="[dropList2 !== false ? activeClass: '', ``]">
            <p @click="openDropListAge()" class="visible-item">

              <span>{{ filter2Chose }}</span>

              <svg class="arrow" width="12" height="7" viewBox="0 0 12 7" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M10.7783 1.24219L6.26562 5.77767" stroke="#00ACE6" stroke-width="2"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.26608 5.77767L1.75684 1.24219" stroke="#00ACE6" stroke-width="2"
                      stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </p>

            <div class="hide-item ">
              <p v-bind:class="[filter2Chose === key.name ? activeClass: '', ``]"
                 v-for="(key, value) in filterItemDrop2" v-html="key.name"
                 @click="choseFilter2(key.type, key.name)"></p>

            </div>
          </div>
        </div>
        <div class="conferences-filter-interesting" v-if="isinteresting">
          <div class="conferences-filter-interesting-past" :class="{active_tab: !isFuture}"
               @click="isFuture = false">
            <span>Минулі конференції</span>
            <span class="score"><b>{{ pastScore }}</b><br><span
                v-html="this.declOfNum(pastScore, ['бал', 'бали', 'балів'])"></span></span>
          </div>
          <div class="conferences-filter-interesting-future" :class="{active_tab: isFuture}"
               @click="isFuture = true">
            <span>Майбутні конференції</span>
            <span class="score"><b>{{ futureScore }}</b><br><span
                v-html="this.declOfNum(futureScore, ['бал', 'бали', 'балів'])"></span></span>
          </div>
        </div>
      </div>

      <div class="conferences-post">
        <div v-if="value.show === true &&  isinteresting === false"
             v-bind:style="{  order: value.order}"
             class="conferences-post-item"
             :id="`${value.id}`"
             v-bind:class="[{ dis: value.year < currentYear.getFullYear() }, {past: value.timer.includes('-')}]"
             v-for="(value, index) in postObject">
          <div
              class="conferences-post-item-registration"
              v-if="value.private"
          >Обов’язкова реєстрація
          </div>

          <div class="content">
            <div class="score">
              <p v-html="value.points"></p>
            </div>

            <div style="color:red;" class="conferences-post-item-small" v-show="value.description !==null">
              {{ value.description }}
            </div>

            <router-link :to="`/single-conferences/${value.id}`" class="conferences-post-item-link">
              <div class="conferences-post-item-title">{{ value.title }}</div>

              <div class="conferences-post-item-video" v-if="value.conferenceVideos.length >= 1">
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.42341 13.5765C3.79319 13.5765 0.846924 10.6302 0.846924 7.00001C0.846924 3.36979 3.79319 0.423523 7.42341 0.423523C11.0536 0.423523 13.9999 3.36979 13.9999 7.00001C13.9999 10.6302 11.0536 13.5712 7.42341 13.5765ZM7.42341 0.949642C4.08256 0.949642 1.37304 3.65916 1.37304 7.00001C1.37304 10.3409 4.08256 13.0504 7.42341 13.0504C10.7643 13.0504 13.4738 10.3409 13.4738 7.00001C13.4738 3.65916 10.7643 0.954903 7.42341 0.949642ZM5.84505 9.89367C5.69774 9.89367 5.582 9.77792 5.582 9.63061V4.36942C5.582 4.2221 5.69774 4.10636 5.84505 4.10636C5.89767 4.10636 5.94502 4.12214 5.98711 4.14845L10.1961 6.77904C10.3171 6.85796 10.3592 7.01579 10.2802 7.14206C10.2592 7.17889 10.2276 7.2052 10.1961 7.22624L5.98711 9.85684C5.94502 9.88314 5.89241 9.89367 5.84505 9.89367ZM6.10811 4.84292V9.1571L9.55419 7.00001L6.10811 4.84292V4.84292Z"
                      fill="white" stroke="white" stroke-width="0.3"/>
                </svg>
                <p>Відеозапис додано</p>
              </div>
            </router-link>

            <div class="conferences-post-item-date">
              <div>
                <span v-html="value.startAt">

                </span>
                <span
                    v-if="value.year >= currentYear.getFullYear() && !value.timer.includes('-') ">(за {{
                    value.timer
                  }})</span>
                <span
                    v-if="value.timer.includes('-')">(відбулось)</span>
              </div>
              <div>{{ value.city }}</div>
            </div>
          </div>

          <div class="conferences-post-item-button">
            <div @click="addToFavorite(value.id, value.timer.includes('-'))"
                 v-if="value.year >= currentYear.getFullYear()">
              <span v-if="favoritesId.includes(value.id) === false">Цікавлюся</span>
              <span v-if="favoritesId.includes(value.id) === true">Видалити з цікавих</span>
            </div>

            <div v-if="value.year < currentYear.getFullYear()">
              <span v-if="favoritesId.includes(value.id) === false">Додати до цікавих</span>
              <span v-if="favoritesId.includes(value.id) === true">Видалити з цікавих</span>
            </div>

            <router-link :to="`/single-conferences/${value.id}`">
              <span>Подробиці</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="conferences-post">
        <div v-if="value.show === true && isinteresting === true && isFuture === !value.timer.includes('undefined')"
             class="conferences-post-item"
             v-for="(value, index) in this.favoritesArray">
          <div
              class="conferences-post-item-registration"
              v-if="value.private"
          >Обов’язкова реєстрація
          </div>
          <div class="content">
            <div class="score">
              <p v-html="value.points"></p>
            </div>

            <div style="color:red;" class="conferences-post-item-small" v-show="value.description !==null">
              {{ value.description }}
            </div>

            <router-link :to="`/single-conferences/${value.id}`">
              <div class="conferences-post-item-title">{{ value.title }}</div>
            </router-link>
            <div class="conferences-post-item-date">
              <div>
                <span v-html="value.startAt"></span>
                <span
                    v-if="value.year >= currentYear.getFullYear() && !value.timer.includes('-')">(за {{
                    value.timer
                  }})</span>
                <span
                    v-if="value.timer.includes('-')">(відбулось)</span>
              </div>
              <div>{{ value.city }}</div>
            </div>
          </div>
          <div class="conferences-post-item-button">

            <div @click="addToFavorite(value.id, true)">
              <span>Видалити з цікавих</span>
            </div>

            <router-link :to="`/single-conferences/${value.id}`">
              <span>Докладніше</span>
            </router-link>
          </div>
        </div>
      </div>

    </section>

  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "conferences",
  data: () => ({
    isYears: false,
    isAreas: false,
    isinteresting: false,
    dropList1: false,
    dropList2: false,
    isFuture: true,
    currentYear: new Date(),

    filterItemDrop1: [
      {
        name: "Вся Україна",
        type: "all"
      }
    ],
    filterItemDrop2: [],

    postObject: [
      {
        id: 1,
        smallText: "Науково-практична конференція з міжнародною участю",
        title:
            "«Складний пацієнт в практиці педіатра (присвячена 60-річчю кафедри педіатрії-2 НМАПО імені П. Л. Шупика)»",
        date: "12 березня, Ср",
        area: "Київ",
        registration: false
      },
      {
        id: 2,
        smallText: "Науково-практична конференція з міжнародною участю",
        title:
            "«Складний пацієнт в практиці педіатра (присвячена 60-річчю кафедри педіатрії-2 НМАПО імені П. Л. Шупика)»",
        date: "12 березня, Ср",
        area: "Київ",
        registration: true
      },
      {
        id: 3,
        smallText: "Науково-практична конференція з міжнародною участю",
        title:
            "«Складний пацієнт в практиці педіатра (присвячена 60-річчю кафедри педіатрії-2 НМАПО імені П. Л. Шупика)»",
        date: "12 березня, Ср",
        area: "Київ",
        registration: false
      }
    ],
    activeClass: "active",

    filter2Chose: "2025",
    filter1Chose: "Вся Україна",
    filter1Type: "Вся Україна",
    filter2Type: "2025",
    favoritesArray: [],
    favoritesId: [],

    pastScoreArray: [],
    futureScoreArray: [],

    pastScore: 0,
    futureScore: 0,


  }),
  mounted() {
    this.getConfData();

    if (sessionStorage.getItem("myConf") === "true") {
      this.isinteresting = true;
      sessionStorage.removeItem("myConf");
    }
  },

  methods: {

    getConfData() {

      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

      axios.get(this.$root.globalUrl + "api/v1/conference/").then((res) => {

        let localArray = res.data;

        localArray.favorites.sort(function (a, b) {
          return new Date(a.startAt) - new Date(b.startAt);
        });

        this.postObject = [];
        this.favoritesArray = [];
        this.favoritesId = [];
        this.pastScoreArray = [];
        this.futureScoreArray = [];
        this.pastScore = 0;
        this.futureScore = 0;

        this.filterItemDrop1 = [
          {
            name: "Вся Україна",
            type: "all"
          }
        ];
        this.filterItemDrop2 = [];

        let cityFilter = Object.keys(localArray.all_filters.city);
        let yearFilter = Object.keys(localArray.all_filters.year);


        cityFilter.forEach((el) => {
          this.filterItemDrop1.push({
            name: el,
            type: el
          })
        });

        yearFilter.forEach((el) => {
          this.filterItemDrop2.push({
            name: el,
            type: el
          })
        });

        const monthNames = ["січня", "лютого", "березня", "квітня", "травня", "червня", "липня", "серпня", "вересня", "жовтня", "листопада", "грудня",];

        const monthDay = [];

        const days = ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

        localArray.favorites.forEach((el) => {

          let currentDate = new Date(el.startAt);
          let endDate = new Date(el.endAt);

          let timeNow = new Date();

          let timeToConf = this.count(currentDate);

          if (currentDate !== endDate && el.endAt !== null && currentDate.getDate() !== endDate.getDate()) {
            el.startAt = currentDate.getDate() + " " + monthNames[currentDate.getMonth()] + " " + "- " + endDate.getDate() + " " + monthNames[endDate.getMonth()] + ",<br>" + days[currentDate.getDay()] + "-" + days[endDate.getDay()] + " "

          } else {
            el.startAt = currentDate.getDate() + " " + monthNames[currentDate.getMonth()] + ",<br>" + days[currentDate.getDay()] + " ";
          }

          el["timer"] = timeToConf + ' ' + this.declOfNum(timeToConf, ["день", "дні", "днів"]);
          el["order"] = timeToConf;
          // el["old"] = timeToConf;

          if (timeToConf <= 0) {
            el.timer = el.timer + " undefined"
            // el.order = 999999;
          }

          if (el.timer.includes('undefined')) {
            this.pastScoreArray.push(el.points);
            this.pastScore = this.pastScoreArray.reduce((a, b) => a + b, 0)
          } else {
            this.futureScoreArray.push(el.points);
            this.futureScore = this.futureScoreArray.reduce((a, b) => a + b, 0)
          }

          el["show"] = true;
          el["year"] = currentDate.getFullYear();
          el['points'] = '<span>' + el.points + '</span>' + ' ' + this.declOfNum(el.points, ['бал', 'бали', 'балів']);

          this.favoritesArray.push(el);
          this.favoritesId.push(el.id)
        });

        localArray.all.forEach((el) => {


          let currentDate = new Date(el.startAt);
          let endDate = new Date(el.endAt);
          let timeNow = new Date();
          let timeToConf = this.count(currentDate);


          if (currentDate !== endDate && el.endAt !== null && currentDate.getDate() !== endDate.getDate()) {
            el.startAt = currentDate.getDate() + " " + monthNames[currentDate.getMonth()] + " " + "- " + endDate.getDate() + " " + monthNames[endDate.getMonth()] + ",<br>" + days[currentDate.getDay()] + "-" + days[endDate.getDay()] + " ";

          } else {
            el.startAt = currentDate.getDate() + " " + monthNames[currentDate.getMonth()] + ",<br>" + days[currentDate.getDay()] + " ";
          }

          el["timer"] = timeToConf + ' ' + this.declOfNum(timeToConf, ["день", "дні", "днів"]);
          el["show"] = true;
          el["order"] = timeToConf;
          // el["old"] = timeToConf;
          el["year"] = currentDate.getFullYear();

          // if (timeToConf <= 0) {
          //   el.order = 999999;
          // }

          el['points'] = '<span>' + el.points + '</span>' + ' ' + this.declOfNum(el.points, ['бал', 'бали', 'балів']);

          this.postObject.push(el)
        })

        this.filterFunction();
      })
    },

    count(timeStamp) {
      // let currentDate = timeStamp;
      // let setdate2 = new Date(currentDate.getFullYear(), 0, 1, 0, 0);
      // let day2 = (currentDate - setdate2) / 1000 / 60 / 60 / 24;
      // day2 = Math.round(day2);

      // let now = new Date();
      // let setdate = new Date(now.getFullYear(), 0, 1, 0, 0);
      // let day = (now - setdate) / 1000 / 60 / 60 / 24;
      // day = Math.round(day);

      // return day2 - day;

      let currentDate = timeStamp;
      let now = new Date();

      var daysLag = Math.ceil((currentDate.getTime() - now.getTime()) / (1000 * 3600 * 24));

      return daysLag;
    },

    addToFavorite(id, trigger) {
      if (!trigger) {


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("key");

        axios.post(this.$root.globalUrl + "api/v1/conference/", {
          "id": id
        }).then((res) => {
          this.getConfData();

        }).catch((error) => {

        })
      }

    },

    declOfNum(number, titles) {
      let cases = [2, 0, 1, 1, 1, 2];
      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    },

    choseFilter1(value, name) {

      this.filter1Chose = name;
      this.dropList1 = false;
      this.filter1Type = value;

      this.filterFunction()

    },

    choseFilter2(value, name) {

      this.filter2Chose = name;
      this.dropList2 = false;
      this.filter2Type = value;

      this.filterFunction()
    },

    openDropListAge() {

      if (this.dropList2 === false && this.dropList1 !== true) {
        this.dropList2 = true
      } else {
        this.dropList2 = false
      }
    },

    openDropListNeed() {
      if (this.dropList1 === false && this.dropList2 !== true) {

        this.dropList1 = true

      } else if (this.dropList1 === false && this.dropList2 === true) {

        setTimeout(() => {
          this.dropList1 = true
        }, 500);

        this.dropList2 = false

      } else {

        this.dropList1 = false

      }
    },

    filterFunction() {

      this.postObject.forEach((el) => {

        el.show = true;

        if (el.city !== this.filter1Type && this.filter1Chose !== "Вся Україна" && this.filter1Type !== "all") {

          el.show = false;

        } else if (this.filter1Type === "all") {

          el.show = true;
        }

        if (el.year !== parseInt(this.filter2Type) && this.filter2Chose !== "Весь рік") {

          el.show = false;

        } else if (this.filter2Type === "all") {

          el.show = true;

        }
      })

      const getDate = (data, target) =>
          data.reduce((acc, obj) =>
              obj.show === true && obj.order <= 0 && Math.abs(target - obj.order) < Math.abs(target - acc.order) ? obj : acc
          );

      this.$nextTick(() => {


        let top = document.getElementById(getDate(this.postObject, 0).id).getBoundingClientRect().top - document.querySelector('.conferences-filter').getBoundingClientRect().height - 20

        window.scrollTo({
          top: top,
          left: 0,
          behavior: 'smooth'
        });

      })
    },
  }
};
</script>

<style lang="scss">
@import "../style/vars";

.conferences-page {
  font-family: "OpenSans-Bold";
  position: relative;
  background: #eff3f8;

  .drop-item1, .drop-item2 {
    cursor: pointer;
    border-top: 1px solid #DAE3ED;
    padding: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    transition: background-color .5s ease;

    .visible-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        display: flex;
        font-size: 15px;
        font-family: $fontR;
        color: #1E3B89;
      }

      .icon {
        display: flex;
        margin-right: 20px;
      }

      .arrow {
        transition: transform .2s ease;
        display: flex;
        margin-left: auto;
      }
    }

    .hide-item {
      overflow-y: scroll;
      padding: 0 15px;
      z-index: 10;
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      max-height: 0;
      background: #E0ECF7;
      /*overflow: hidden;*/
      transition: padding .5s ease, max-height .5s ease;


      p {
        padding: 10px 0;
        color: #1E3B89;
        font-size: 15px;
        font-family: $fontL;

        &.active, &:hover {
          font-family: $fontR;
        }
      }
    }

    &.active {
      background: #E0ECF7;

      .hide-item {
        border-top: 1px solid #DAE3ED;
        max-height: 500px;
        padding: 10px 15px;
      }

      .arrow {
        transform: rotate(180deg);
      }
    }
  }

  .head-links {
    background-color: rgba(255, 255, 255, 0.49);
    width: 100%;
    padding: 10px 15px;

    .back-links {
      color: #7392B1;
      font-family: $fontR;
      font-size: 11px;
    }

    span {
      padding: 0 5px;
      color: #7392B1;
      font-family: $fontR;
      font-size: 11px;
    }

    .now-page {
      color: #00ACE6;
      font-family: $fontSB;
      font-size: 11px;
    }
  }

  .conferences {
    padding: 15px;
    padding-bottom: 0;

    &-filter {
      padding: 0.1px;
      margin-bottom: 30px;
      background: #fff;
      z-index: 10;
      position: sticky;
      top: 0;

      &-title {
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        text-transform: uppercase;
        color: #9ab9d8;
        letter-spacing: 0.11em;
        padding: 11px 0;
        border-bottom: 1px solid #dfe7ef;
      }

      &-interesting {
        > div {
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: normal;
          padding: 20px;
          color: #1e3b89;
          transition: all 0.3s;
        }

        &-past {
          cursor: pointer;
          border-top: 1px solid #DAE3ED;

          display: flex;
          justify-content: space-between;
          align-items: center;

          .score {

            background: #00ACE6;
            border-radius: 17px;

            padding: 5px 10px;
            font-size: 7px;
            font-family: $fontR;
            color: white;

            text-align: center;
            line-height: 1.3em;

            b {
              font-family: $fontB;
              font-size: 12px;
            }
          }
        }

        &-future {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .score {

            background: #00ACE6;
            border-radius: 17px;

            padding: 5px 10px;
            font-size: 7px;
            font-family: $fontR;
            color: white;

            text-align: center;
            line-height: 1.3em;

            b {
              font-family: $fontB;
              font-size: 12px;
            }
          }
        }
      }

      &-button {
        margin: 13px 0;
        display: flex;
        cursor: pointer;

        > div {
          width: 50%;
          text-align: center;
          position: relative;
        }

        &-all {
          border-right: 1px solid #dfe7ef;
        }
      }

      &-select {
        font-size: 15px;
        color: #1e3b89;

        .arrow-icon {
          background: url(../assets/img/arrow.svg) right center no-repeat;
          background-size: 9px 5px;
        }

        &-years {
          padding: 20px;
          border-bottom: 1px solid #e8edf4;
          border-top: 1px solid #e8edf4;
        }

        &-areas {
          padding: 20px;
        }

        &-list {
          height: 175px;
          overflow: hidden;
          overflow-y: auto;
          background: #e0ecf7;

          > div {
            > div {
              padding: 12px 20px;
              color: #1e3b89;
              font-size: 15px;
            }
          }
        }
      }
    }

    .icon {
      width: 37px;
      height: 41px;
      margin: auto;
      position: relative;
    }

    .count {
      width: 25px;
      height: 25px;
      background: #00ace6;
      border-radius: 50%;
      color: #fff;
      font-size: 12px;
      position: absolute;
      right: -8px;
      top: -5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    /*POST CONTENT*/
    &-post {

      display: flex;
      flex-direction: column;


      &-item {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        background: #fff;
        margin-bottom: 29px;
        overflow: hidden;

        &.past {

          .conferences-post-item-small, .conferences-post-item-title {
            color: #9AB9D8 !important;
          }

          .conferences-post-item-date {
            div {
              color: #9AB9D8 !important;
            }

            > div:first-child {
              background: url(../assets/img/date_icon-past.svg) left top no-repeat;
              background-size: 13px 14px;
              padding-left: 18px;
            }

            > div:last-child {
              background: url(../assets/img/area_icon-past.svg) left center no-repeat;
              background-size: 11px 14px;
              padding-left: 16px;

              display: flex;
              align-items: center;
            }
          }

          .conferences-post-item-button {
            border-top: 1px solid #DAE3ED;

            > div, a {
              display: block;
              width: 50%;
              text-align: center;
              font-size: 10px;
              padding: 5px 0;
              font-weight: normal;
            }

            > div:first-child, a:first-child {
              color: #9AB9D8;
              border-right: 1px solid #DAE3ED;

              span {
                background: url(../assets/img/callendar_icon-past.svg) left center no-repeat;
                background-size: 12px 15px;
                padding-left: 17px;
              }
            }

            > a:last-child {
              color: #1e3b89;

              span {
                background: url(../assets/img/search_icon.svg) left center no-repeat;
                background-size: 14px 15px;
                padding-left: 18px;
              }
            }
          }


          > div.content {
            padding: 15px;
            position: relative;

            display: flex;
            flex-direction: column;

            .score {
              background: rgba(154, 185, 216, 0.15) !important;

              p {
                color: #9AB9D8 !important;

                span {
                  color: #9AB9D8 !important;
                }
              }
            }
          }


        }

        &.dis {
          .conferences-post-item-button {
            div {
              opacity: 0.3;
            }
          }
        }

        > div.content {
          padding: 15px;
          position: relative;

          display: flex;
          flex-direction: column;

          .score {

            position: absolute;
            right: 0;
            top: 0;
            width: 44px;
            height: 44px;

            background: rgba(0, 172, 230, 0.15);
            display: flex;
            justify-content: center;
            border-radius: 0 0 0 10px;

            p {
              text-align: center;
              color: #00ACE6;
              font-family: $fontR;
              font-size: 9px;
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;

              span {
                font-family: $fontB;
                line-height: 10px;
                font-size: 15px;
              }
            }
          }
        }

        &-registration {
          text-align: center;
          background: #1e3b89;
          color: #fff;
          font-size: 12px;
          padding: 10px 0;
        }

        &-small {
          font-family: $fontB;
          font-size: 12px;
          color: rgba(30, 59, 137, 0.7);
          padding: 10px 0;
          margin-right: 44px;
        }

        &-video {
          display: inline-flex;
          align-items: center;

          background: #00ACE6;
          border-radius: 4px;
          margin-top: 12px;

          padding: 8px 10px 8px 5px;

          svg {
            max-width: 15px;
            width: 100%;
            height: auto;
            display: block;
          }

          p {
            padding-left: 5px;
            color: #FFFFFF;

            font-family: $fontSB;
            font-size: 12px;
          }
        }

        &-link {
        }

        &-title {
          font-size: 15px;
          color: #1e3b89;
          font-weight: 600;
          margin-right: 44px;
        }

        &-date {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin: 12px 0 0 0;
          width: 100%;

          > div:first-child {
            font-size: 10px;
            color: #1e3b89;
            background: url(../assets/img/date_icon.svg) left top no-repeat;
            background-size: 13px 14px;
            padding-left: 18px;

          }

          > div:last-child {
            font-size: 10px;
            color: #1e3b89;
            background: url(../assets/img/area_icon.svg) left top no-repeat;
            background-size: 11px 14px;
            padding-left: 16px;

            display: flex;
            align-items: flex-start;
          }
        }

        &-button {
          border-top: 1px solid #dfe7ef;
          text-transform: uppercase;

          cursor: pointer;

          display: flex;
          justify-content: space-between;
          padding: 10px 0;

          > div, a {
            display: block;
            width: 50%;
            text-align: center;
            font-size: 10px;
            padding: 5px 0;
            font-weight: normal;
          }

          > div:first-child, a:first-child {
            color: #00ace6;
            border-right: 1px solid #e3eaf1;

            span {
              background: url(../assets/img/callendar_icon.svg) left center no-repeat;
              background-size: 12px 15px;
              padding-left: 17px;
            }
          }

          > a:last-child {
            color: #1e3b89;

            span {
              background: url(../assets/img/search_icon.svg) left center no-repeat;
              background-size: 14px 15px;
              padding-left: 18px;
            }
          }
        }
      }
    }
  }

  .active_button {
    position: relative;

    &::after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -13px;
      border-bottom: 2px solid #00ace6;
    }
  }

  .active_tab {
    background: #E0ECF7;
  }

  .fade-list-enter-active,
  .fade-list-leave-active {
    transition: opacity 0.5s;
  }

  .fade-list-enter,
  .fade-list-leave-to {
    opacity: 0;
  }
}
</style>
